body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ccc;
  background-color: #282c34;

  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.back-button {
  top: 0;
  left: 0;
  position: absolute;
  margin: 1em;
  padding: 0.5em;
  border: 1px solid #ccc;
  color: #ccc;
  background-color: transparent;
}

.app {
  
  border: 1px solid #ccc;
  padding: 10px;

}

.play-button {
  width: 3em;
  height: 3em;
}

.socials-bar {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}
.socials-bar img {
  width: 2.5em;
  height: 2.5em;
  margin: 0 0.5em;
}